import React from 'react';
import logo_white from '../assets/img/logo_white.png';

export const Navbar = () => {
  return (
    <nav className="bg-[#fffcf3] p-4 flex items-center justify-between">
      {/* Logo Section */}
      <a href="https://www.inested.com">
      <div className="flex items-center">
        <img
          src={logo_white} // Replace with actual logo path
          alt="Inested Logo"
          className="h-8 mr-4"
        />
      </div>
      </a>

      {/* Centered Tab Links */}
      <div className="flex-grow flex justify-center space-x-6">
        <a href="https://www.inested.com/pub/community" className="text-green font-semibold hover:text-black-500">
          Scholarship Opportunities
        </a>
        
        <a href="https://www.inested.com/blogs" className="text-green font-semibold hover:text-black-500">
          Blogs
        </a>
        <a href="https://www.inested.com/webinars" className="text-green font-semibold hover:text-black-500">
            Webinar/Podcasts
        </a>
      </div>
    </nav>
  );
};

{/* <Link href={'https://service.inested.com/'}>
							<Button
								className={` text-base  ${
									pathname.startsWith('/services')
										? 'bg-[#107561]/10 text-[#107561]'
										: 'bg-transparent'
								} shadow-none text-[#107561] hover:bg-transparent
					 w-full`}
							>
								Services
							</Button>
						</Link> */}