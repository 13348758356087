import Accounts from './accounts';
import Profile from './auth/Profile';
import ResetPassword from './auth/ResetPassword';
import CustomerRequests from './customer-requests';
import Home from './home';
import MyRequests from './myrequests';
import Requests from './requests';
import Services from './services';

export const routesApp = [
  {
    path: 'home',
    component: Home,
    permission: null,
  },

  {
    path: 'requests',
    component: Requests,
    permission: null,
  },

  {
    path: 'myrequests',
    component: MyRequests,
    permission: null,
  },

  {
    path: 'customerrequests',
    component: CustomerRequests,
    permission: null,
  },

  {
    path: 'accounts',
    component: Accounts,
    permission: null,
  },

  {
    path: 'profile',
    component: Profile,
    permission: null,
  },

  {
    path: 'services',
    component: Services,
    permission: null,
  },

  

  
];
