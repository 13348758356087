// src/pages/ResetPassword.js
import React, { useState } from 'react';
import { Form, Input, Button, Card, Row, Col, Typography, message } from 'antd';
import { LockTwoTone } from '@ant-design/icons';
import { supabase } from '../../utils/supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';

const { Title } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordReset, setPasswordReset] = useState(false);

  // Extract access token from the URL, which is passed by Supabase in the email link
  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get('access_token');

  const onFinish = async (values) => {
    setLoading(true);

    if (!accessToken) {
      message.error('Invalid password reset token.');
      setLoading(false);
      return;
    }

    try {
      // Set the new password using the access token
      const { error } = await supabase.auth.updateUser({
        password: values.password,
      }, { accessToken });

      if (error) {
        message.error(error.message);
      } else {
        message.success('Password reset successfully! Redirecting to login...');
        setPasswordReset(true);
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
      }
    } catch (error) {
      message.error('Something went wrong, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fullscreen">
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} xl={8}>
          <Card>
            <Row justify="center">
              <Title level={3}>Reset Password</Title>
            </Row>

            <Form
              layout="vertical"
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your new password!',
                  },
                  {
                    min: 6,
                    message: 'Password must be at least 6 characters!',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockTwoTone />}
                  placeholder="New Password"
                />
              </Form.Item>

              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your new password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Passwords do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockTwoTone />}
                  placeholder="Confirm New Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>

            {passwordReset && (
              <Typography.Paragraph style={{ color: 'green', textAlign: 'center' }}>
                Password reset successful! Redirecting...
              </Typography.Paragraph>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
