import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Space = styled.div`
  font-family: 'Space Grotesk', sans-serif;
`;

export const FirstDecorator = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [service] = useState([
    {
      id: 1,
      title: 'Resume Review',
      description:
        'Get a professional review of your resume to make it stand out and land your dream job.',
    },
    {
      id: 2,
      title: 'Grad School Application Assistance',
      description:
        'Personalized guidance on preparing and submitting your graduate school applications.',
    },
    {
      id: 3,
      title: 'Personal Statement Writing',
      description:
        'Craft compelling personal statements that reflect your achievements and goals.',
    },
    {
      id: 4,
      title: 'Mock Interview Preparation',
      description:
        'Ace your interviews with practice sessions and feedback from experts.',
    },
    {
      id: 5,
      title: 'LinkedIn Profile Optimization',
      description:
        'Optimize your LinkedIn profile to attract recruiters and expand your network.',
    },
  ]);

  // Update the currentIndex to cycle through the services
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % service.length);
    }, 3000); // Change every 3 seconds
    return () => clearInterval(interval);
  }, [service.length]);

  return (
    <Space>
      <div className="bg-gradient-to-b from-[#f3b557] to-[#1a9b66] rounded-[15px] p-5 flex flex-col justify-center items-center relative overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.div
            key={service[currentIndex].id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 17,
            }}
            className="bg-white shadow-sm drop-shadow-xl z-10 rounded-[8px] border-[1.5px] p-6 flex flex-col justify-center items-center"
            style={{
              maxWidth: '100%',
              width: '100%',
              maxHeight: '500px', // You can adjust maxHeight as per your requirement
            }}
          >
            <h3 className="text-lg font-bold text-green-500 py-2 px-4 border border-green-500 rounded-md inline-block mb-4">
              --- Our Services ---
            </h3>

            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              {service[currentIndex].title}
            </h3>

            <p className="text-gray-600 text-sm text-center">
              {service[currentIndex].description}
            </p>
          </motion.div>
        </AnimatePresence>
      </div>
    </Space>
  );
};
