import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaYoutube } from 'react-icons/fa';

export const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-8">
      <div className="container mx-auto px-4">
        {/* Footer Top Section */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          {/* Company Info */}
          <div>
            <h2 className="text-lg font-bold text-white mb-3">Inested Services</h2>
            <p className="text-sm">
              Inested LLC
              1250 Leona St. <br />
              Texas, Tx, 77009
            </p>
            <p className="text-sm mt-2">
              <FaEnvelope className="inline-block mr-2" />
              info@inested.com
            </p>
          </div>

          {/* Social Media Links */}
          <div className="mt-6 md:mt-0">
            <h2 className="text-lg font-bold text-white mb-3">Follow Us</h2>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61561143167091"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500 transition duration-300"
              >
                <FaFacebookF size={20} />
              </a>
              <a
                href="https://x.com/officialinested"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 transition duration-300"
              >
                <FaTwitter size={20} />
              </a>
              <a
                href="https://www.youtube.com/@Inested"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-pink-500 transition duration-300"
              >
                <FaYoutube size={20} />
              </a>
              <a
                href="https://www.linkedin.com/in/nested/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-600 transition duration-300"
              >
                <FaLinkedin size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm">
            © {new Date().getFullYear()} Inested. All Rights Reserved.
          </p>
          <p className="text-sm mt-4 md:mt-0">
            By <a href="https://yourwebsite.com" className="text-blue-400 hover:underline">Inested Team</a>
          </p>
        </div>
      </div>
    </footer>
  );
};
